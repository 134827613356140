/** @format */

import React from "react";

const Sidebar = ({ isOpenSidebar, handleOpenSidebar }) => {
  return (
    <>
      {/* <div
        className="w-[380px] h-full bg-[#8BBE3F]"
        style={{ boxShadow: "-20px 4px 37px -6px rgba(0, 0, 0, 0.25)" }}
      >
        <h2
          className="font-bold text-[34px] leading-tight text-white"
          style={{ textShadow: "3px 3px 5px rgba(0, 0, 0, 0.25)" }}
        >
          WELCOME TO ORBITAL LIVINGS
        </h2>
        <h4
          className="font-bold text-2xl leading-tight text-white"
          style={{ textShadow: "2px 2px 6px rgba(0, 0, 0, 0.5)" }}
        >
          Let’s Get Connected
        </h4>
        <ul>
          <li
            className="font-bold text-base leading-tight text-white"
            style={{ textShadow: "2px 2px 6px rgba(0, 0, 0, 0.5)" }}
          >
            <span>
              <img src="/assets/phone.svg" alt="" className="" />
            </span>
            <span>
              +88 01713 461 622 <br /> +88 01959 555 999
            </span>
          </li>{" "}
          <li
            className="font-bold text-base  leading-tight text-white"
            style={{ textShadow: "2px 2px 6px rgba(0, 0, 0, 0.5)" }}
          >
            <span>
              <img src="/assets/email.svg" alt="" className="" />
            </span>
            <span>hazi.fazlurrahman@gmail.com</span>
          </li>{" "}
          <li
            className="font-bold text-base leading-tight text-white"
            style={{ textShadow: "2px 2px 6px rgba(0, 0, 0, 0.5)" }}
          >
            <span>
              <img src="/assets/location.svg" alt="" className="" />
            </span>
            <span>
              KA-6, Haveily Complex (3rd Floor), Jagannathpur, Basundhara Main
              Road, Dhaka 1229, Bangladesh
            </span>
          </li>
        </ul>
      </div> */}

      <div
        className={`fixed right-0 top-0 z-[999] h-full w-full md:w-[380px] md:p-[30px] pb-10 transition-all duration-300 ease-linear bg-[#8BBE3F] ${
          isOpenSidebar ? "translate-x-0" : "translate-x-full"
        }`}
      >
        <div className="">
          <button
            className="hidden md:block"
            // onClick={() => setIsOpenSidebar(!isOpenSidebar)}
            onClick={handleOpenSidebar}
            //   style={{ marginLeft: isOpenSidebar ? "-100px" : "" }}
            style={
              isOpenSidebar ? { marginLeft: "-100px", marginTop: "15px" } : {}
            }
          >
            <img src="/assets/close-icon.svg" alt="" />
          </button>
          <div className="flex justify-between w-full md:hidden py-10 bg-[#EFEFEB] px-[30px] md:px-0">
            <div className="">
              <img
                src="/assets/site-logo.png"
                alt=""
                className="w-[150px] md:w-[200px]"
              />
            </div>
            <button
              className=" block md:hidden"
              // onClick={() => setIsOpenSidebar(!isOpenSidebar)}
              onClick={handleOpenSidebar}
            >
              <img src="/assets/close-icon.svg" className="w-8" alt="" />
            </button>
          </div>
        </div>

        <div className="mb-[30px] md:px-0 px-[30px] pt-10 md:pt-32">
          <h2 className="font-bold text-3xl md:text-[34px] font-RobotoSlab leading-tight text-white">
            WELCOME TO ORBITAL LIVINGS
          </h2>
          <h4 className="font-bold text-2xl font-RobotoSlab leading-tight uppercase text-white pt-5 md:pt-16">
            Let’s Get <br /> Connected
          </h4>
          <ul>
            <li className="font-bold text-base font-RobotoSlab leading-tight text-white flex pt-5 md:pt-14">
              <span className="w-1/5">
                <img
                  src="/assets/phone.svg"
                  alt=""
                  className="w-[46px] h-[46px]"
                />
              </span>
              <span className="w-4/5">
                +88 01713 461 622 <br /> +88 01959 555 999
              </span>
            </li>{" "}
            <li className="font-bold font-RobotoSlab text-base  leading-tight text-white flex pt-5 md:pt-14 items-center">
              <span className="w-1/5">
                <img
                  src="/assets/email.svg"
                  alt=""
                  className="w-[46px] h-[46px]"
                />
              </span>
              <span className="w-4/5">hazi.fazlurrahman@gmail.com</span>
            </li>{" "}
            <li className="font-bold font-RobotoSlab text-base leading-tight text-white flex pt-5 md:pt-14">
              <span className="w-1/5">
                <img
                  src="/assets/location.svg"
                  alt=""
                  className="w-[46px] h-[46px]"
                />
              </span>
              <span className="w-4/5">
                KA-6, Haveily Complex (3rd Floor), Jagannathpur, Basundhara Main
                Road, Dhaka 1229, Bangladesh
              </span>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
