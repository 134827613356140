/** @format */

import React from "react";

const Footer = () => {
  return (
    <>
      <footer className="fixed bottom-4 left-0 z-10 w-full">
        <div className="container mx-auto px-4">
          <p className="font-RobotoSlab text-sm hidden md:block">
            © 2023 All Rights Reserved. Technology Partner:{" "}
            <a href="https://www.mavengers.com/" className="" target="_blank">
              Mavengers Limited.
            </a>
          </p>
          <p className="font-RobotoSlab text-center text-sm block md:hidden">
            © 2023 All Rights Reserved. <br /> Technology Partner:{" "}
            <a href="https://www.mavengers.com/" className="" target="_blank">
              Mavengers Limited.
            </a>
          </p>
        </div>
      </footer>
    </>
  );
};

export default Footer;
