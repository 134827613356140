/** @format */

import React, { useState } from "react";
import Sidebar from "./Sidebar";

const Header = () => {
  const [isOpenSidebar, setIsOpenSidebar] = useState(false);
  const handleOpenSidebar = () => {
    setIsOpenSidebar(!isOpenSidebar);
  };
  return (
    <>
      <div className="fixed top-0 left-0 z-10 w-full">
        <div className="container mx-auto px-4 w-full">
          <div className="flex justify-between w-full py-5 md:py-10">
            <div className="">
              <img
                src="/assets/site-logo.png"
                alt=""
                className="w-[150px] md:w-[200px] md:ml-6"
              />
            </div>
            <div className="cursor-pointer" onClick={handleOpenSidebar}>
              <img
                src="/assets/menu-bar.svg"
                alt=""
                className="w-10 md:w-auto"
              />
            </div>
          </div>
        </div>
      </div>

      <Sidebar
        isOpenSidebar={isOpenSidebar}
        handleOpenSidebar={handleOpenSidebar}
      />
    </>
  );
};

export default Header;
