/** @format */

import React, { useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
const LandingSlider = () => {
  const beforeChange = (prev, next) => {
    let elements = document.querySelectorAll(".slick-slide h3");
    elements.forEach((element) => {
      element.classList.remove("animate", "delay-3", "slide-left", "!visible");
    });
    elements = document.querySelectorAll(".slick-slide h2");
    elements.forEach((element) => {
      element.classList.remove("animate", "delay-4", "slide-left", "!visible");
    });
    elements = document.querySelectorAll(".slick-slide p");
    elements.forEach((element) => {
      element.classList.remove("animate", "delay-5", "slide-left", "!visible");
    });
    elements = document.querySelectorAll(".slick-slide a");
    elements.forEach((element) => {
      element.classList.remove("animate", "delay-6", "slide-left", "!visible");
    });
  };

  const afterChange = (index) => {
    let elements = document.querySelectorAll(".slick-active h3");
    elements.forEach((element) => {
      element.classList.add("animate", "delay-3", "slide-left", "!visible");
    });
    elements = document.querySelectorAll(".slick-active h2");
    elements.forEach((element) => {
      element.classList.add("animate", "delay-4", "slide-left", "!visible");
    });
    elements = document.querySelectorAll(".slick-active p");
    elements.forEach((element) => {
      element.classList.add("animate", "delay-5", "slide-left", "!visible");
    });
    elements = document.querySelectorAll(".slick-active a");
    elements.forEach((element) => {
      element.classList.add("animate", "delay-6", "slide-left", "!visible");
    });
  };
  const settings = {
    dots: false,
    infinite: true,
    // speed: 5000,
    autoplaySpeed: 7000,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    // fade: true,
    autoplay: true,
    beforeChange,
    afterChange,
  };
  useEffect(() => {
    afterChange();
  }, []);

  return (
    <>
      <Slider {...settings}>
        <div className="h-screen w-screen relative">
          <div
            className="!bg-cover z-[-1] absolute !bg-no-repeat h-full w-full !hidden md:!block !bg-center"
            style={{ background: "url('/assets/1.png')" }}
          ></div>
          <div
            className="!bg-cover z-[-1] absolute !bg-no-repeat h-full w-full !block md:!hidden !bg-center"
            style={{ background: "url('/assets/mobile-01.png')" }}
          ></div>
          {/* <img
            src="/assets/1.png"
            alt=""
            className="z-[-1] absolute h-full w-full !hidden md:!block object-cover"
          /> */}
          {/* <img
            src="/assets/mobile-01.png"
            alt=""
            className="z-[-1] absolute h-full w-full !block md:!hidden object-cover"
          /> */}
          <div className="container mx-auto px-4 md:px-6 hero__slider h-full w-full">
            <div className="flex flex-col md:flex-row md:items-center h-full w-full mt-32 md:mt-0">
              <div className="w-full md:w-1/2">
                <div className="">
                  <h3 className="font-bold font-PlayfairDisplay text-xl text-center md:text-left md:text-[40px] leading-tight uppercase my-2 invisible md:w-[600px] text-[#8DBF3F]">
                    Our Story
                  </h3>

                  <p className="text-xs md:text-base leading-relaxed font-RobotoSlab tracking-wide text-black font-normal mb-8 invisible md:w-[521px] text-center md:text-left">
                    Orbital Livings is the pioneer in high-end real estate. We
                    develop apartments & offer plots, ready flats, and property
                    advice within prime locations in Dhaka. With more than 30
                    years of experience in real estate, our dedicated team is
                    capable of trustworthiness, commitment, and excellent
                    customer service. At orbital Livings, we provide expert and
                    impartial advice to secure the finest property on the best
                    possible terms.
                  </p>
                </div>
              </div>
              {/* <img
                src="/assets/mobile-1.png"
                alt=""
                className="!block md:!hidden object-contain"
              /> */}
            </div>
          </div>
        </div>
        <div className="h-screen w-screen relative">
          <div
            className="!bg-cover z-[-1] absolute !bg-no-repeat h-full w-full !hidden md:!block !bg-center"
            style={{ background: "url('/assets/2.png')" }}
          ></div>
          <div
            className="!bg-cover z-[-1] absolute !bg-no-repeat h-full w-full !block md:!hidden !bg-center"
            style={{ background: "url('/assets/mobile-02.png')" }}
          ></div>
          {/* <img
            src="/assets/2.png"
            alt=""
            className="z-[-1] absolute h-full w-full !hidden md:!block object-cover"
          />
          <img
            src="/assets/mobile-02.png"
            alt=""
            className="z-[-1] absolute h-full w-full !block md:!hidden object-cover"
          /> */}
          <div className="container mx-auto px-4 md:px-6 hero__slider h-full w-full">
            <div className="flex flex-col md:flex-row md:items-center h-full w-full mt-32 md:mt-0">
              <div className="w-full md:w-1/2">
                <div className="">
                  <h3 className="font-bold font-PlayfairDisplay text-xl text-center md:text-left md:text-[40px] leading-tight uppercase my-2 invisible md:w-[600px] text-[#8DBF3F]">
                    Let Us Guide You Home
                  </h3>

                  <p className="text-xs md:text-base leading-relaxed font-RobotoSlab tracking-wide text-black font-normal mb-8 invisible md:w-[521px] text-center md:text-left">
                    We buy high-end and super-prime residential property for
                    private and corporate clients. We also develop apartments in
                    potential locations. Without being all things to all people,
                    our service is highly personalized. We work with a limited
                    number of clients at one time, so fewer clients result in a
                    far superior service. We want every client to feel like they
                    are the only one.
                  </p>
                </div>
              </div>
              {/* <img
                src="/assets/mobile-2.png"
                alt=""
                className="!block md:!hidden object-contain"
              /> */}
            </div>
          </div>
        </div>
        <div className="h-screen w-screen relative">
          <div
            className="!bg-cover z-[-1] absolute !bg-no-repeat h-full w-full !hidden md:!block !bg-center"
            style={{ background: "url('/assets/3.png')" }}
          ></div>
          <div
            className="!bg-cover z-[-1] absolute !bg-no-repeat h-full w-full !block md:!hidden !bg-center"
            style={{ background: "url('/assets/mobile-03.png')" }}
          ></div>
          {/* <img
            src="/assets/3.png"
            alt=""
            className="z-[-1] absolute h-full w-full !hidden md:!block object-cover"
          />
          <img
            src="/assets/mobile-03.png"
            alt=""
            className="z-[-1] absolute h-full w-full !block md:!hidden object-cover"
          /> */}
          <div className="container mx-auto px-4 md:px-6 hero__slider h-full w-full">
            <div className="flex flex-col md:flex-row md:items-center h-full w-full mt-32 md:mt-0">
              <div className="w-full md:w-1/2">
                <div className="">
                  <h3 className="font-bold font-PlayfairDisplay text-xl text-center md:text-left md:text-[40px] leading-tight uppercase my-2 invisible md:w-[600px] text-[#8DBF3F]">
                    Why Choose Us?
                  </h3>

                  <p className="text-xs text-center md:text-left md:text-base leading-relaxed font-RobotoSlab tracking-wide text-black font-normal mb-8 invisible md:w-[521px]">
                    We offer a hassle-free experience for property dealing. Our
                    main priority is to ensure a secure property purchase
                    agreement. We always keep in mind that our clients get a
                    sophisticated experience.
                  </p>
                </div>
              </div>
              {/* <img
                src="/assets/mobile-3.png"
                alt=""
                className="!block md:!hidden object-contain"
              /> */}
            </div>
          </div>
        </div>
        {/* <div className="h-screen relative w-screen">
          <img
            src="/assets/2.png"
            alt=""
            className="z-[-1] absolute h-full w-full hidden md:block"
          />
          <img
            src="/assets/mobile-02.png"
            alt=""
            className="z-[-1] absolute h-full w-full block md:hidden"
          />
          <div className="container mx-auto px-4 md:px-6 hero__slider h-full w-full">
            <div className="flex flex-col-reverse md:flex-row items-center h-full w-full">
              <div className="w-full md:w-1/2">
                <div className="">
                  <h3 className=" font-bold font-PlayfairDisplay text-[40px] leading-tight uppercase my-2 invisible md:w-[600px] text-[#8DBF3F]">
                    Let Us Guide You Home
                  </h3>

                  <p className="text-lg font-RobotoSlab leading-relaxed tracking-wide text-black font-normal mb-8 invisible md:w-[521px]">
                    We buy high-end and super-prime residential property for
                    private and corporate clients. We also develop apartments in
                    potential locations. Without being all things to all people,
                    our service is highly personalized. We work with a limited
                    number of clients at one time, so fewer clients result in a
                    far superior service. We want every client to feel like they
                    are the only one.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        {/* <div className="h-screen relative w-screen">
          <img
            src="/assets/3.png"
            alt=""
            className="z-[-1] absolute h-full w-full md:block hidden"
          />
          <img
            src="/assets/mobile-03.png"
            alt=""
            className="z-[-1] absolute h-full w-full block md:hidden"
          />
          <div className="container mx-auto px-4 md:px-6 hero__slider h-full w-full">
            <div className="flex flex-col-reverse md:flex-row items-center h-full w-full">
              <div className="w-full md:w-1/2">
                <div className="">
                  <h3 className=" font-bold font-PlayfairDisplay text-[40px] leading-tight uppercase my-2 invisible md:w-[600px] text-[#8DBF3F]">
                    Why Choose Us?
                  </h3>

                  <p className="text-lg leading-relaxed font-RobotoSlab tracking-wide text-black font-normal mb-8 invisible md:w-[521px]">
                    We offer a hassle-free experience for property dealing. Our
                    main priority is to ensure a secure property purchase
                    agreement. We always keep in mind that our clients get a
                    sophisticated experience.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </Slider>
    </>
  );
};

export default LandingSlider;
