/** @format */

import LandingSlider from "../component/LandingSlider";
const Home = () => {
  return (
    <>
      <LandingSlider />
    </>
  );
};

export default Home;
